import { getUser } from '@/adapter/auth0/auth0'
import { isHostettlerServiceManager } from '@/adapter/auth0/roles'
import Page from '@/components/layout/page'
import FleetButton from '@/components/management/fleet-button'
import { loadAllFleetOwners, loadFleetOwnersByEmail } from '@/domain/fleet/fleet-owner.prisma'
import { FleetOwner } from '@/domain/fleet/types'
import { loadAllDisabledGarages, loadAllEnabledGarages, loadGarageByEmail } from '@/domain/garage/garage.prisma'
import { Garage } from '@/domain/garage/types'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { GetServerSideProps } from 'next'
import Link from 'next/link'
import router from 'next/router'
import { Buildings, Car, EnvelopeOpen, Money, Plus } from 'phosphor-react'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import styles from './index.module.css'

interface Props {
  loggedIn: boolean
  hostettlerServiceManager: boolean
  fleetOwners: FleetOwner[]
  enabledGarages: Garage[]
  disabledGarages: Garage[]
}

export default function Home({
  loggedIn,
  hostettlerServiceManager,
  fleetOwners,
  enabledGarages: garages,
  disabledGarages,
}: Props) {
  const fleetOwnerUpto = fleetOwners.find((f) => f.customerId === '273475')
  const fleetOwnerMigros = fleetOwners.find((f) => f.customerId === '273475-mig')
  const [cookie] = useCookies(['locale'])

  useEffect(() => {
    if (cookie.locale !== i18n.locale) {
      i18n.activate(cookie.locale)
      router.push(router.asPath, router.asPath, { locale: cookie.locale })
    }
  }, [])

  return (
    <Page title={t`Fleet Assistant der hostettler autotechnik ag`}>
      <h1>
        <Trans>Fleet Assistant der hostettler autotechnik ag</Trans>
      </h1>

      {loggedIn && !hostettlerServiceManager && (
        <div>
          <p>
            <Trans>Willkommen beim Fleet Assistant, Sie sind als Flotten-Nutzer angemeldet.</Trans>
          </p>
          <p>
            <Trans>Sie haben Zugriff auf folgende Flotten:</Trans>
          </p>
        </div>
      )}

      {hostettlerServiceManager && (
        <>
          <h2>
            <Trans>Management</Trans>
          </h2>

          <section className={styles.managementLinksSection}>
            <Link href={`/management/garages`}>
              <div className={`button buttonContent`}>
                <Buildings fontSize={20} /> {t`Garagen`}
              </div>
            </Link>
            <Link href="management/invoicing">
              <div className={`button buttonContent`}>
                <Money fontSize={20} /> {t`Verrechnung`}
              </div>
            </Link>
            <Link href="management/mail-templates">
              <div className={`button buttonContent`}>
                <EnvelopeOpen fontSize={20} /> {t`Mail-Vorlagen`}
              </div>
            </Link>
            <Link href="management/fleet-owner/new">
              <div className={`button buttonContent`}>
                <Plus fontSize={20} /> {t`Flotte hinzufügen`}
              </div>
            </Link>
            {/* 
            <Link href="management/garage-map">
              <div className={`button buttonContent`}>
                <Lifebuoy fontSize={20} /> Unterstützung Offerte
              </div>
            </Link>
            */}
          </section>

          <h2>
            <Trans>Reifenwechsel</Trans>
          </h2>
          <section className={styles.managementLinksSection}>
            {fleetOwnerUpto && (
              <Link href={`/management/car-maintenance-preparation/${fleetOwnerUpto?.fleetOwnerId}`}>
                <div className={`button buttonContent`}>
                  <Car fontSize={20} /> {`UPTO`}
                </div>
              </Link>
            )}
            {fleetOwnerMigros && (
              <Link href={`/management/car-maintenance-preparation/${fleetOwnerMigros?.fleetOwnerId}`}>
                <div className={`button buttonContent`}>
                  <Car fontSize={20} /> {`Migros Genossenschaftsbund`}
                </div>
              </Link>
            )}
            {fleetOwners
              .filter((f) => f.fleetType == 'ALLINONE')
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((fleetOwner) => (
                <Link
                  key={fleetOwner.fleetOwnerId}
                  href={`/management/car-maintenance-preparation/${fleetOwner.fleetOwnerId}`}
                >
                  <div className={`button buttonContent`}>
                    <Car fontSize={20} /> {fleetOwner.name}
                  </div>
                </Link>
              ))}
          </section>
        </>
      )}
      {fleetOwners.length > 0 && (
        <>
          <h2>
            <Trans>Flotten</Trans>
          </h2>
          {hostettlerServiceManager && (
            <ul>
              {fleetOwners
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((fleetOwner) => (
                  <li key={fleetOwner.fleetOwnerId}>
                    <Link href={`/fleetOwner/${fleetOwner.fleetOwnerId}`}>{fleetOwner.name}</Link>
                  </li>
                ))}
            </ul>
          )}
          {!hostettlerServiceManager && (
            <div style={{ margin: '10px 0' }}>
              {fleetOwners
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((fleetOwner) => (
                  <FleetButton key={fleetOwner.fleetOwnerId} fleetOwner={fleetOwner} />
                ))}
            </div>
          )}
        </>
      )}

      {loggedIn && garages.length > 0 && (
        <>
          <h2>
            <Trans>Arbeitsrapporte Garagen</Trans>
          </h2>
          <ul>
            {garages.map((garage) => (
              <li key={garage.garageNumber}>
                <Link href={`/garages/${garage.garageNumber}`}>{garage.garageName}</Link>
              </li>
            ))}
          </ul>
          <h2>
            <Trans>Inaktive Garagen</Trans>
          </h2>
          <ul>
            {disabledGarages.map((disabledGarage) => (
              <li key={disabledGarage.garageNumber}>
                <Link href={`/garages/${disabledGarage.garageNumber}`}>{disabledGarage.garageName}</Link>
              </li>
            ))}
          </ul>
        </>
      )}

      {!loggedIn && (
        <>
          <p>
            <Trans>
              Willkommen im Fleet Assistant der hostettler autotechnik ag. Wenn Sie das erste mal hier sind,
              registrieren Sie sich bitte, um alle weiteren Aufgaben im Fleet Assistant auszuführen.
            </Trans>
          </p>
          <p>
            <a href={`/api/auth0/signup?lang=${i18n.locale}`} className="button">
              <Trans>Registrieren</Trans>
            </a>
          </p>
          <p>
            <Trans>Sie sind bereits registriert? Melden Sie sich bitte an:</Trans>
          </p>
          <p>
            <a href={`/api/auth0/login?lang=${i18n.locale}`} className="button">
              <Trans>Anmelden</Trans>
            </a>
          </p>
        </>
      )}
    </Page>
  )
}

export const getServerSideProps: GetServerSideProps<Props, any> = async (context) => {
  const user = await getUser(context.req, context.res)
  const props: Props = {
    enabledGarages: [],
    disabledGarages: [],
    loggedIn: false,
    hostettlerServiceManager: false,
    fleetOwners: [],
  }
  if (user) {
    props.loggedIn = true
    const original_path = context.req.cookies.original_path
    if (original_path) {
      context.res.setHeader('Set-Cookie', 'original_path=/; path=/; Max-Age=0')
      return {
        redirect: {
          permanent: false,
          destination: `${original_path}`,
        },
      }
    }
    if (isHostettlerServiceManager(user)) {
      props.hostettlerServiceManager = true
      const enabledGarages = await loadAllEnabledGarages()
      const disabledGarages = await loadAllDisabledGarages()
      props.enabledGarages = enabledGarages.sort(sortByGarageName)
      props.disabledGarages = disabledGarages.sort(sortByGarageName)
      props.fleetOwners = await loadAllFleetOwners()
    } else {
      const userGarages = (await loadGarageByEmail(user.email)).sort(sortByGarageName)
      const userFleets = await loadFleetOwnersByEmail(user.email)
      if (userGarages.length === 1 && !userFleets.length) {
        // Redirect to the garage page if it is the only garage of the user
        return {
          redirect: {
            permanent: false,
            destination: `/garages/${userGarages[0].garageNumber}`,
          },
        }
      } else if (!userGarages.length && userFleets.length === 1) {
        return {
          redirect: {
            permanent: false,
            destination: `/fleetOwner/${userFleets[0].fleetOwnerId}`,
          },
        }
      } else {
        props.enabledGarages = userGarages
        props.fleetOwners = userFleets
      }
    }
  }
  return { props }
}

const sortByGarageName = (garageA: Garage, garageB: Garage) => garageA.garageName.localeCompare(garageB.garageName)
